@import 'media-query';

@mixin generate-flex-classes() {
  @for $i from 1 through 10 {
    .flex-#{$i} {
      flex: $i;
    }
  }
}

@include generate-flex-classes;

@mixin transition($properties: all, $duration: 0.4s, $timing-function: ease-in-out) {
  transition: $properties $duration $timing-function;
}

$breakpoints: (
  'web': $bp-lg,
);
$state-colors: (
  success: (
    dark: var(--ion-color-success-dark),
    light: var(--ion-color-success),
  ),
  error: (
    dark: var(--ion-color-danger),
    light: var(--ion-color-error-light),
  ),
  info: (
    dark: var(--ion-color-info-dark),
    light: var(--ion-color-info),
  ),
  warning: (
    dark: var(--ion-color-warning-dark),
    light: var(--ion-warning-info),
  ),
);

@mixin success-states($dark-accent-color, $light-accent-color) {
  .title-container,
  .highlight-container {
    background-color: $light-accent-color;
  }

  .title-container {
    border-bottom: 2px solid $dark-accent-color;
  }

  .highlight-container {
    border-radius: 8px;
    padding: 8px;
    color: $dark-accent-color;

    ion-button {
      --background: var(--ion-color-primary-contrast);
    }
  }

  ion-icon {
    color: $dark-accent-color;
  }

  .modal-title {
    color: $dark-accent-color;
  }
}

@mixin state-colors($state) {
  @include success-states(
    map-get($state-colors, $state, dark),
    map-get($state-colors, $state, light)
  );
}
