ion-select {
  background-image: url('/assets/icon/ic_chevron-down.svg');
  background-size: 24px;
  background-position: calc(100% + 6px);
  background-repeat: no-repeat;
  text-align: right;
  margin-inline-start: 0;
  font-weight: 400 !important;
  min-height: 40px;

  --highlight-color-valid: unset;
  --border-color: var(--ion-color-step-300) &.select-expanded {
    background-image: url('/assets/icon/ic_chevron-up.svg');
  }
}

ion-select::part(icon) {
  display: none !important;
  margin-right: 50px;
}

ion-select[disabled] {
  background-image: none;
}
