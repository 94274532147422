ion-item,
efp-plan-list-item {
  background: var(--ion-color-primary-contrast);

  --ion-color-base: var(--ion-color-primary-contrast);
  --background-hover: none;
  --background-focused: none;
  --background: var(--ion-color-primary-contrast);
  --ion-item-background: var(--ion-color-primary-contrast);
  --highlight-color-focused: var(--ion-color-primary);
  --highlight-color-valid: var(--ion-color-primary);
  --highlight-color-invalid: var(--ion-color-danger);

  &.option-menu-item {
    --inner-padding-end: 0px !important;
  }

  .option-menu-container {
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon {
      height: 16px;
      width: 16px;
    }
  }

  &[button] {
    --background-focused: var(--ion-color-primary);

    &:hover {
      --background: var(--efp-hover-high-contrast) !important;

      background: var(--efp-hover-high-contrast) !important;
    }

    &:active {
      --background: var(--efp-light-blue) !important;

      background: var(--efp-light-blue) !important;
    }
  }
}

efp-plan-list-item {
  border-bottom: 1px solid #ccc;

  ion-item {
    --border-width: 0 !important;
  }
}
