/**
 * Contains global utility classes for simple CSS stuff.
 */

@import 'media-query';

.d-flex {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.d-block {
  display: block;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.ion-margin-small {
  margin-inline: var(--ion-margin, 8px) var(--ion-margin, 8px);

  --margin-start: var(--ion-margin, 8px);
  --margin-end: var(--ion-margin, 8px);
  --margin-top: var(--ion-margin, 8px);
  --margin-bottom: var(--ion-margin, 8px);

  margin: var(--ion-margin, 8px);
}

.ion-margin-horizontal-small {
  margin-inline: var(--ion-margin, 8px) var(--ion-margin, 8px);

  --margin-start: var(--ion-margin, 8px);
  --margin-end: var(--ion-margin, 8px);

  margin-left: var(--ion-margin, 8px);
  margin-right: var(--ion-margin, 8px);
}

.ion-margin-vertical-small {
  --margin-top: var(--ion-margin, 8px);
  --margin-bottom: var(--ion-margin, 8px);

  margin-top: var(--ion-margin, 8px);
  margin-bottom: var(--ion-margin, 8px);
}
