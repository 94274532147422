@import 'media-query';

form {
  ion-label.measureUnit {
    margin: 0;
    padding-left: 2px;
  }

  ion-label {
    min-width: fit-content;
    flex: 0 0 auto;
  }

  ion-input {
    --color: var(--ion-color-primary);
    --highlight-color: unset !important;
  }

  .combobox-container.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.form-container {
  justify-content: space-between;
  padding: 0 12px;

  @media (min-width: $bp-lg) {
    padding: 0;
  }

  .title {
    font-weight: 500;
    line-height: 20px;
  }

  .label-container {
    align-items: center;

    ion-label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--ion-color-medium-shade);
    }
  }
}

.form-container,
.modal-form-container {
  ion-select {
    background-position-x: calc(100% - 12px);

    &:hover {
      border: 1px solid var(--ion-color-primary);
      background-image: url('/assets/icon/ic_chevron-down.svg');
      background-color: var(--efp-hover-high-contrast) !important;
    }

    &::part(placeholder) {
      font-style: italic;
    }

    &.select-expanded {
      background-image: url('/assets/icon/ic_chevron-up.svg');
    }
  }

  ion-select,
  ion-input {
    background-color: var(--ion-color-primary-contrast);
    border: 1px solid var(--ion-color-dark);
    border-radius: 4px;
    text-align: start;
    color: var(--ion-color-medium-shade);
    font-size: 14px;
    line-height: 20px;

    --padding-top: 8px;
    --padding-end: 16px;
    --padding-bottom: 8px;
    --padding-start: 16px;
    --placeholder-font-style: italic;
    --placeholder-opacity: 1;

    &.danger {
      border: 1px solid var(--ion-color-danger);
      background-color: var(--ion-color-error-light);
    }
  }
}
