@import 'media-query';

.efp-circle-floating-button {
  &[disabled] {
    cursor: default !important;
    opacity: 1;
    pointer-events: none;
  }

  &.light {
    --background: #eff0f0;
    --color: var(--ion-color-primary);

    &[disabled] {
      --background: #eff0f0 !important;
      --color: var(--IX_Disabled_LowContrast, #c8c9cb);
    }

    &:hover {
      --background: var(--IX_Hover_LowContrast, #003a73);
      --color: white;
    }
  }

  &.dark {
    --background: var(--ion-color-primary, #004588);
    --color: white;

    &[disabled] {
      --background: var(--IX_Disabled_LowContrast, #c8c9cb);
      --color: white;
    }
  }

  span {
    display: none;
  }
}
