﻿@import 'media-query';

$floating-button-height: 60px;
$floating-button-height-web: 48px;
$floating-button-vertical-margin: 24px;

.efp-floating-button-container {
  width: inherit;
  position: fixed;
  bottom: 16px;
  display: flex;
  justify-content: center;
  z-index: unset;
  gap: 16px;
  flex-wrap: wrap;

  &.safe-area-bottom {
    bottom: calc(env(safe-area-inset-bottom) + 16px);
  }

  @media (min-width: $bp-lg) {
    bottom: 24px;
    gap: 24px;
  }
}

.floating-button-overscroll-placeholder {
  height: calc(#{$floating-button-height} + 2 * #{$floating-button-vertical-margin});
}

@media (min-width: $efp-web-breakpoint) {
  .floating-button-overscroll-placeholder {
    height: calc(#{$floating-button-height-web} + 2 * #{$floating-button-vertical-margin});
  }

  .floating-button-container {
    gap: 24px;

    ion-button.floating-button {
      height: $floating-button-height-web;
      width: fit-content;

      span {
        display: block;
      }

      ion-icon {
        margin-right: 8px;
      }
    }
  }
}
