/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Roboto font imports, used by Ionic */
@import '@fontsource/roboto';
@import '@fontsource/roboto/500.css';
@import '@uag/fonts';
@import 'material-symbols/outlined.css';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
// @import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import './theme/utils';
@import './theme/mixins';
@import './theme/overrides';
@import './theme/components';
@import 'media-query';
@tailwind base;
@tailwind components;
@tailwind utilities;

ion-tab-button {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 4px solid transparent;

  &.tab-selected {
    border-bottom: 4px solid var(--ion-color-primary);
  }
}

ion-button {
  @apply button;

  text-transform: none !important;
}

ion-header {
  --ion-background-color: var(--ion-color-secondary);
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-primary);

  box-shadow: unset !important;

  ion-col {
    align-items: center;
    display: flex;
  }

  ion-icon,
  ion-button,
  ion-tab-button {
    --color: var(--ion-color-primary);
  }
}

ion-title {
  font-size: 20px;
}

.iconSize24 {
  width: 24px !important;
  height: 24px !important;
}

.iconSizeL {
  font-size: 64px;
  height: 64px;
  width: 64px;
}

.iconSizeS {
  font-size: 32px;
}

.ion-text-bold {
  font-weight: 500;
}

.ion-text-regular {
  font-weight: 400;
}

.ion-input-border {
  border: 1px solid #909090;

  --padding-start: 0px !important;
}

ion-menu {
  ion-label,
  ion-icon {
    --color: var(--ion-text-color) !important;
  }
}

#menuButton {
  ion-icon {
    --color: var(--ion-color-primary);
  }

  ion-menu-button {
    --color: var(--ion-color-primary);
  }
}

ion-list {
  padding-top: 0 !important;

  // because of differnet background colors
  padding-bottom: 0 !important;
}

.accordion-list {
  display: flex;
  flex-direction: column;

  @media (min-width: $efp-web-breakpoint) {
    gap: 8px;
    padding-top: 8px;
  }
}

.ion-font-bold {
  font-weight: 900;
}

.ion-smallHeadline {
  display: block;
  color: #000;
  font-weight: 300;
  font-size: 14px;
}

.ion-date {
  font-size: 12px !important;
}

.ion-ma-top-5 {
  margin-top: 5px;
}

.alertStyleTwoButtons {
  button span {
    text-align: center;
    justify-content: center !important;
  }
}

.alertStyle {
  .alert-wrapper {
    --border-radius: 0px !important;

    border-radius: 25px !important;
    width: 280px;
  }

  .alert-message {
    color: var(--ion-text-color, #000);
  }

  h2:not(.alert-sub-title) {
    text-align: center;
    font-weight: 900 !important;
    color: var(--ion-color-primary, #004588) !important;
  }

  .alert-radio-group {
    border-top: none !important;
  }

  .alert-head.sc-ion-alert-md {
    border-bottom: 1px solid #dedfe0;
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 10%));
  }
}

.disable-action-sheet-btns {
  cursor: not-allowed;
  background-color: rgba(229, 229, 229) !important;
  pointer-events: none;
}

.selectAlert .alert-message {
  color: var(--ion-color-danger);
}

.selectCustomHeader {
  .selectHeader {
    .alert-radio-icon {
      display: none;
    }

    .alert-radio-label {
      padding-left: 1rem;
      background-color: var(--ion-background-color);
    }
  }

  .alert-head {
    padding-left: 16px;
  }
}

.projectOverviewAlertDelete {
  .alert-message {
    border-bottom: 1px solid var(--ion-color-step-150, #d9d9d9);
  }
}

$btnStyle-bottom: 3%;
$btnStyle-height: 50px;
$btnStyle-vertical-margin: 4px;

.btnStyle {
  width: 85%;
  left: 0;
  right: 0;
  margin: $btnStyle-vertical-margin auto;
  text-align: center;

  --background-color: var(--ion-color-primary, #004588) !important;

  background-color: var(--ion-color-primary, #004588) !important;
  color: white;

  --ion-color-contrast: white !important;
  --ion-color-base: var(--ion-color-primary, #004588) !important;
  --color: white;

  border-radius: 20px !important;
  font-size: 20px;
  height: $btnStyle-height;
  min-height: $btnStyle-height;
  font-weight: bold;
  z-index: 950;

  &[disabled] {
    opacity: 1;

    --background: var(--ion-color-medium) !important;
  }
}

.btnStyle-sticky {
  position: sticky; //with absolute it only works when placed inside the content => z-index issues
  top: calc(100% - #{$btnStyle-bottom} - #{$btnStyle-height});
}

.btnStyle-fixed {
  position: fixed;
  bottom: $btnStyle-bottom;
}

.btnStyle-overscroll-placeholder {
  height: calc(#{$btnStyle-bottom} + #{$btnStyle-height} + 2 * #{$btnStyle-vertical-margin});
}

.alert-wrapper [aria-checked='true'].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: var(--ion-text-color);

  --color: var(--ion-text-color);
}

ion-app {
  --ion-background-color: #eff0f0;
  --ion-background-color-rgb: 239, 240, 240;
  --ion-text-color: #383c40;
  --ion-text-color-rgb: 56, 60, 64;
}

.removeInlinePadding {
  padding-inline: 0 0 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
  white-space: normal;
}

ion-modal {
  --box-shadow: none;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.5) !important;

  &.screenshot-modal {
    --width: 100%;
    --height: 100%;
    --background: transparent;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.8) !important;
  }
}

// START image zoom in 2D - PDF export:
.product-zoom-modal {
  --width: 100%;

  background: rgba(255, 255, 255, 90%);
}

.sc-ion-modal-md-h {
  --background: none;
}

.sc-ion-modal-ios-h {
  --background: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

// END

/**
edit-detail-view-container for edit pages of plan
**/

.edit-detail-view-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  ion-content {
    flex: 3;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

// END
ion-toolbar.context-menu {
  ion-buttons {
    display: flex;

    @media (min-width: $bp-lg) {
      flex-direction: column;
    }

    ion-icon {
      color: var(--ion-color-primary);
    }
  }
}

// remove this!
ion-app > :nth-child(2) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// for ion-tabs replacement routing
.router-wrapper {
  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  contain: layout size style;
}

.router-wrapper > :nth-child(2) {
  position: relative;
  flex: 1;
  contain: layout size style;
}
