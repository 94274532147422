.card-container {
  width: 162px;
  height: 242px;
  overflow: hidden;

  --padding-start: 0;

  @media (min-width: $bp-lg) {
    width: 264px;
    height: 352px;
  }

  &-inner {
    ion-note {
      font-size: 12px;
      line-height: 20px;

      @media (min-width: $bp-lg) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    ion-button {
      --border-radius: 8px;
    }
  }
}
