.media-mobile-only {
  @media (min-width: $bp-lg) {
    display: none;
  }
}

.media-web-only {
  display: none;

  @media (min-width: $bp-lg) {
    display: flex;
  }
}

.mobile-container {
  padding-left: 12px;
  padding-right: 12px;
  margin: 0;

  @media (min-width: $bp-lg) {
    padding-left: unset;
    padding-right: unset;
  }

  &.full-width {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $efp-web-breakpoint) {
      padding-left: 12px;
      padding-right: 12px;

      @media (min-width: $bp-lg) {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }
}

.d-flex-responsive-wrap {
  flex-direction: column;

  @media (min-width: $efp-web-breakpoint) {
    flex-direction: row;
    gap: 16px;
  }
}

.d-flex-responsive-gap {
  gap: 4px;

  @media (min-width: $efp-web-breakpoint) {
    gap: 16px;
  }
}
