.efp-toggle-buttons {
  display: flex;
  align-items: center;

  ion-button {
    border: 1px solid var(--ion-color-primary);
    height: 40px;

    --border-radius: 0;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &[disabled] {
      opacity: 1;
      background-color: var(--efp-light-blue);
    }
  }

  @media (min-width: $bp-lg) {
    margin-top: 0;
    flex-direction: column;

    ion-button {
      width: 40px !important;
      height: 48px;

      &:first-child {
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
      }
    }
  }

  ion-button::part(native) {
    padding: unset;
  }
}
